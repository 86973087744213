<script lang="ts" setup>
import ButtonSecondary from "~/components/ButtonSecondary.vue";

// Properties
const props = defineProps({
    product: {
        type: Object,
    },
    showButton: {
        type: Boolean,
        default: false,
    },
});

// Data
const config = useRuntimeConfig();
const sampleLink = ref("");

// Get CW resource
let requestURL =
    config.public.CW_API +
    `/resource?api_abc_token=${config.public.CW_API_TOKEN}&resource_id=${props.product?.coursewave_product_id}`;
// If local, then default resource ID = 1
if (config.public.CW_API.includes("coursewave.test")) {
    requestURL = config.public.CW_API + `/resource?api_abc_token=${config.public.CW_API_TOKEN}&resource_id=1`;
}
const { data: response } = await useLazyFetch(requestURL);
let cwResource = {
    id: 0,
    ebook_sample_pages: [],
};

if (response.value && response.value.resource) {
    cwResource = response.value.resource;
    if (cwResource && cwResource.ebook_sample_pages && cwResource.ebook_sample_pages[0]) {
        const pageNum = cwResource.ebook_sample_pages[0];
        sampleLink.value = `${config.public.CW_HOST}/samples/resources/${cwResource.id}/preview-eBook?page=${pageNum}`;
    }
}
</script>

<template>
    <div class="md:block">
        <ButtonSecondary
            v-if="showButton && sampleLink"
            type="link"
            :href="sampleLink"
            target="_blank"
            class="text-sm"
            py="py-1"
        >
            Look Inside
        </ButtonSecondary>
    </div>
</template>

<style scoped></style>
